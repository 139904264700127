<template>
  <div>
    <!-- SECTION modal-modify-remarks-code-fly-together -->
    <b-modal
      id="modal-modify-remarks-code-fly-together"
      :title="$t('reservation.remarks.remarkCodeFlyTogether')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShow"
    >
      <BFormGroup label-for="remarks-input">
        <template #label>
          <div class="text-nowrap font-weight-bolder">
            Mã đặt chỗ:
          </div>
        </template>
        <b-form-input
          id="remarks-input"
          v-model="remarksContent"
          style="font-size: 16px; line-height: 24px;"
          class="text-uppercase"
          lazy-formatter
          :formatter="removeAccentsUpperCaseFormatter"
        />
      </BFormGroup>

      <template #modal-footer="{ close }">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25 border-0"
          :disabled="!remarksContent"
          pill
          @click="sendRemarksHandle"
        >
          <span class="align-middle">{{ $t('reservation.confirm') }}</span>
        </b-button>
      </template>
    </b-modal>

    <ModalCommandResponse
      :functionName="functionName"
      :titleModal="$t('reservation.remarks.remarkCodeFlyTogether')"
      :response="response"
    />
  </div>
</template>

<script>
import {
  BModal, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'
import { isEmpty } from 'lodash-es'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import ModalCommandResponse from './ModalCommandResponse.vue'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BFormGroup,
    ModalCommandResponse,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { sendQueue } = useReservationHandle()
    const bookingData = toRefs(props).bookingData
    const remarksContent = ref('')
    const response = ref()
    const functionName = ref('RemarksCodeFlyTogether')

    function sendRemarksHandle() {
      this.$bvModal.show('modal-api-loading')
      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        agencyCode: bookingData.value.agency,
        queueCode: 'TWC',
        remark: remarksContent.value,
      }

      sendQueue(payload)
        .then(res => {
          if (!isEmpty(res?.booking?.[0]?.data)) {
            response.value = {
              booking: res.booking[0].data.split('\n'),
            }
          }
        })
        .catch(() => {
          response.value = null
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-modify-remarks-code-fly-together')
          this.$bvModal.show(`modal-command-response-${functionName.value}`)
        })
    }

    function handleShow() {
      remarksContent.value = ''
      response.value = undefined
    }

    return {
      sendRemarksHandle,
      remarksContent,
      removeAccentsUpperCaseFormatter,
      handleShow,
      response,
      functionName,
    }
  },
}
</script>
