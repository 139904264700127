var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-remarks-code-fly-together",
      "title": _vm.$t('reservation.remarks.remarkCodeFlyTogether'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShow
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25 border-0",
          attrs: {
            "disabled": !_vm.remarksContent,
            "pill": ""
          },
          on: {
            "click": _vm.sendRemarksHandle
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      }
    }])
  }, [_c('BFormGroup', {
    attrs: {
      "label-for": "remarks-input"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-nowrap font-weight-bolder"
        }, [_vm._v(" Mã đặt chỗ: ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticClass: "text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "id": "remarks-input",
      "lazy-formatter": "",
      "formatter": _vm.removeAccentsUpperCaseFormatter
    },
    model: {
      value: _vm.remarksContent,
      callback: function callback($$v) {
        _vm.remarksContent = $$v;
      },
      expression: "remarksContent"
    }
  })], 1)], 1), _c('ModalCommandResponse', {
    attrs: {
      "functionName": _vm.functionName,
      "titleModal": _vm.$t('reservation.remarks.remarkCodeFlyTogether'),
      "response": _vm.response
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }